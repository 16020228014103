<template>
  <div v-bind:style='backgroundImage' class='justify-content-between home-main'>
    <div class='align-items-center d-flex flex-column h-50 w-100'>
      <div class='align-self-end me-3 mt-3'>
        <button @click='navigateToRules' class='rules-btn text-white'>?</button>
      </div>

      <div class='h-50 w-75'>
        <img :src="homeScreenElements['logo']" alt='logo' class='h-100 w-100' />
      </div>
      <div
        className='align-items-center coins-container d-flex justify-content-center mt-5 w-50'
      >
        <div className=''>
          <span className='fs-4'>{{this.coins}} {{ translationsFormatter('coins_text') }}</span>
        </div>
        <div className='p-2 w-25'>
          <img :src="homeScreenElements['singleCoin']" className='w-100' />
        </div>
      </div>
    </div>

    <div className='align-items-center d-flex flex-column h-25 w-100'>
      <button
        @click='navigateToCollectCoins'
        type='button'
        class='playBtn fs-3 text-light w-75'
      >
        {{ translationsFormatter('play_button') }}
      </button>
      <button v-if="brandConfig.brand !== 'ais-tai-tha'"
              @click='navigateToLeaderBoard'
              type='button'
              class='fs-4 mt-3 text-light winnerBtn'
      >
        {{ translationsFormatter('winners_button') }}
      </button>
    </div>
  </div>
</template>

<script>
  import { staticImages } from '@/constants/imageUrlPath';
  import { mapState } from 'vuex';

  export default {
    name: 'HomeScreenUi',
    data() {
      return {
        homeScreenElements: {
          logo: staticImages.logo,
          singleCoin: staticImages.singleCoin,
        },
        coins: 0
      };
    },
    methods: {
      navigateToCollectCoins() {
        this.$router.push({ name: 'collectcoins', query: { msisdn: this.user.msisdn } });
      },
      navigateToLeaderBoard() {
        this.$router.push({ name: 'leaderboard' });
      },
      navigateToRules() {
        this.$router.push({ name: 'rules' });
      },
    },
    computed: {
      ...mapState(['brandConfig', 'user']),
      backgroundImage() {
        return {
          '--home-main-background': `url(${staticImages['homeScreen']})`,
          '--coins-container-background': `url(${staticImages['coinsBg']})`,
        };
      },
    },
    beforeMount() {
      this.coins = this.user.coinBalance ?? 0;
      console.log(`User: ${JSON.stringify(this.user)}`);
    }
  };
</script>

<style lang='css'>
  @import './index.css';
</style>

export const translations = {
  'game_begins_in': 'GAME BEGINS IN...',
  'login_playing_button': 'Start Playing',
  'login_term_conditions_text': 'This service costs N200.00/week. Subscription Service. T&C\'s Apply. Cancel anytime',
  'login_info_text': 'stand a chance to win airtime & other great prizes. Enter your ceLL number below to start playing.',
  'login_error_text': 'oops! there was a problem with logging you in!',
  'login_try_again_text': 'try again',
  'loading_text': 'Loading',
  'winners_button': 'Winners',
  'play_button': 'Play',
  'back_button': 'Back',
  'play_again_button': 'Play Again',
  'home_button': 'Home',
  'purchase_button': 'Purchase',
  'top_up_button': 'Top Up',
  'confirm_button': 'Confirm',
  'coins_text': 'Coins',
  'rules_header_text': 'Rules & How to pLay',
  'rules_point_one': 'Collect 50 free coins everyday. (The coins reset everyday at 12:00)',
  'rules_point_two': 'Use 10 coins per game (with a chance to win your opponents coins)',
  'rules_point_three': 'Accumulate coins to gain more entries into the daily draw Battle online players & gain as many points as possible.',
  'rules_point_four': 'Each player has 8, 15 seconds turns to gain as many points as possible.',
  'rules_point_five': 'Play on the same board as your opponent and see their real time moves 1 coins = 1 Entry into the daily draw.',
  'rules_point_six': 'Want to play more but out of coins? Top up with airtime or wait tiLL 12:00',
  'leaderboard_header': 'Leaderboard',
  'leaderboard_column_name': 'Name',
  'leaderboard_column_entries': 'entries',
  'leaderboard_column_prize': 'Prize',
  'coin_collect_text': 'Collect coins',
  'coin_tomorrow_text': 'Come back tomorrow to earn 50 more',
  'coin_earned_text': 'You have earned',
  'tutorial_prompt_1': 'hey! someone stole my coins & I need to get them back. please can you help me!',
  'tutorial_prompt_2': `hello! welcome to mega match rivals I'm so happy you are here! Chira and I need your help.`,
  'tutorial_prompt_3': 'oh, you want your coins back? well I have an idea...',
  'tutorial_prompt_4': 'I knew you\'d take them! give them back!',
  'tutorial_prompt_5': 'how about this... let\'s play a game, if you win, I\'ll give the coins back but if I win, I get to keep them.',
  'tutorial_prompt_6': 'don\'t worry chira, we\'ll help you! let\'s go get those coins.',
  'tutorial_prompt_7': '"please destroy this rude rhino & get my coins back for me! I can\'t do it on my own.',
  'tutorial_prompt_8': 'you need to match 3 jewels (or more) to gain points, get the most points and win! but it\'s not that easy, you\'ll be playing against an opponent! \nyou both get eight turns, 25 seconds each to get as many points as possible whilst playing on the same board!',
  'tutorial_prompt_9': 'let\'s go kick his butt!',
  'skip_tutorial_text': 'Skip tutorial',
  'tap_button_text': 'Tap to continue',
  'prompt_name_text': 'well done on defeating brutis now its time for real challenge! but first you must tell me your name',
  'create_username_text': 'Create your username',
  'select_character_text': '& select your character!',
  'input_username_text': 'Enter Username',
  'submit_button': 'Submit',
  'enter_game_text': 'You are about to enter into an online game.',
  'confirm_game_text': 'Please click confirm to begin searching for an opponent',
  'game_cost_text': 'This game wiLL cost you',
  'search_text': 'searching for ',
  'opponent_text': 'opponent',
  'you_text': 'you',
  'select_text': 'SELECT',
  'powerups_text': 'SELECT 2 POWERUPS',
  'destroy_pieces_text': 'destroy 4 pieces',
  'potential_moves_text': 'view potential moves',
  'double_points_text': 'double points',
  'freeze_time_text': 'freeze time',
  'swap_pieces_text': 'swap any two pieces',
  'reshuffle_text': 'reshuffle board',
  'match_begin_text': 'Match begins in ',
  'opponent_found_text': 'Opponent found',
  'insufficient_funds_header': 'Insufficient funds',
  'insufficient_coins_text': 'You do not have enough coins to pLay again.',
  'top_up_text': 'Top up or come back tomorrow to play!',
  'your_turn_text': 'its your turn! quick, match as many jewels as you can!',
  'top_funds_header': 'top up funds',
  'select_bundle_text': ' Please select a bundle to continue',
  'continue_button': 'Continue',
  'beat_brutis_text': 'Yay! You beat Brutus and got my coins back. Thank you so much!',
  'your_turn_loader_text': 'Your turn',
  'opponent_turn_loader_text': 'Opponent\'s Turn',
};

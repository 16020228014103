import dotenv from 'dotenv';

dotenv.config();

export const productionConfig = {
  upstreamAisHost: `${process.env.VUE_APP_UPSTREAM_AIS_HOST}`,
  gameServerHost: `${process.env.VUE_APP_GAME_SERVER_HOST}`,
  websocketHost: `${process.env.VUE_APP_GAME_SERVER_SOCKET_HOST}`,
};

export const stagingConfig = {
  upstreamAisHost: `${process.env.VUE_APP_UPSTREAM_AIS_HOST}`,
  gameServerHost: `${process.env.VUE_APP_GAME_SERVER_HOST}`,
  websocketHost: `${process.env.VUE_APP_GAME_SERVER_SOCKET_HOST}`,
};

const environment = getEnvironment();

export const environmentConfig = getEnvObject();

function getEnvironment() {
  let base_url = window.location.origin;
  if (base_url.includes('staging')) return 'staging';
  if (base_url.includes('localhost')) return 'development';
  else return 'production';
}

function getEnvObject() {
  if (environment === 'staging') return stagingConfig;
  if (environment === 'development') return productionConfig;
  if (environment === 'production') return productionConfig;
}
